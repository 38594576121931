import React from 'react';
import Main from 'layouts/Main';
import {
  FAQ,
  CurrentPageNavText,
  ContactUs,
  BannerWithButton
} from 'customComponents';


const Contact = () => {

  return (
    <Main>
      <CurrentPageNavText navLocText={"Home > Contact Us"} />
      <ContactUs />
      <FAQ />
      <BannerWithButton
        text1="Schedule a quick call with our team."
        text2="We're excited to see what you have in mind!"
        buttonText="Schedule Call"
        buttonAltText="Free Consultation"
        linkUrl="/schedule-call"
      />
    </Main>
  );
};

export default Contact;
